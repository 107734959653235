<template>
  <div></div>
</template>

<script>
import Auth from "../service/auth_service";
export default {
  created() {
    this.$store.commit("setLoading", true);
    if (Auth.getTypeUser() == 0) {
      this.$router.push(`/${this.$i18n.locale}/continue-register`);
    } else if (Auth.getTypeUser() == 6) {
      this.$router.push(`/${this.$i18n.locale}/medical-center`);
    }
     else if (Auth.getTypeUser() == 3 || Auth.getTypeUser() == 4 || Auth.getTypeUser() == 5) {
      this.$router.push(`/${this.$i18n.locale}/company`);
    } else if (Auth.getTypeUser() == 2) {
      this.$router.push(`/${this.$i18n.locale}/doctor`);
    } else if (Auth.getTypeUser() == 1) {
      this.$router.push(`/${this.$i18n.locale}/patient`);
    } else {
      this.$router.push(`/${this.$i18n.locale}/login`);
    }
    this.$store.commit("setLoading", false);
  },
};
</script>
